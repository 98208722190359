import '../../App.css';
import './Contactus.scss';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import ContactForm from './ContactForm';

function Contactus() {
  return (
    <>
      <Header />
      <div className="contactusContent">
        <h1 className="helveFont">Contact us</h1>
        <ContactForm />
      </div>
      <Footer />
    </>
  );
}

export default Contactus;
