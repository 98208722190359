import { Link } from 'react-router-dom';
import './HeroSection.scss';

function HeroSection() {
  return (
    <section className="hero-section poppins-regular">
      <div className="wallpaperBg h-25rem md:h-30rem">
        <div className="heading-container">
          <h2 className="helveFont text-4xl md:text-7xl">
            AI powered nature <br /> and climate data analytics
          </h2>
        </div>
      </div>

      <div className="last-hero">
        <h2 className="helveFont text-xl md:text-6xl">
          compliance, risk <br /> analysis, management
        </h2>
        <ul>
          <li>Easy to understand and actionable data</li>
          <li>Nature and climate risks in one platform</li>
          <li>Instant results save time and reduce costs</li>
        </ul>
        <button className="bgBtn">
          <Link to="https://calendly.com/kya-christian/30?month=2024-06">
            Book a demo
          </Link>
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
