import './features.scss';
import earth from '../../../assets/earth.png';

function FeaturesSection() {
  return (
    <section className="features-section poppins-regular">
      <h2 className="helveFont title text-2xl md:text-5xl">
        Kuyua helps you to understand and manage business risks associated with
        biodiversity loss and climate change.
      </h2>
      <div className="cards grid ml-0 mr-0">
        <div className="card col-3">
          <h3 className="poppins-bold">Reporting</h3>

          <ul>
            <li>
              double materiality, impacts and dependencies and stakeholder
              engagement
            </li>
            <li>Location analysis and KPIs</li>
            <li>Action plans, transition plans and target setting</li>
            <li>compliance with CSRD, EUDR and others</li>
          </ul>
        </div>
        <div className="card col-3">
          <h3 className="poppins-bold">Business risks</h3>

          <p>
            Nature loss and climate change pose risks to your business.
            Understand these risks for your own locations and your entire supply
            chain, and find out how to address and to adapt to them.
          </p>
        </div>
        <div className="card col-3">
          <h3 className="poppins-bold">Nature positive</h3>

          <p>
            Taylor made, location specific actions to embark on a bespoke path
            to becoming nature positive, protecting precious natural assets {' '}
          </p>
        </div>
        <div className="card col-3">
          <h3 className="poppins-bold">Location analysis</h3>

          <p>
            Supplier screening and decision making Location analysis for
            infrastructure projects
          </p>
        </div>
      </div>

      <div className="earth-container grid">
        <div className="earth col-12 md:col-6">
          <img src={earth} alt="earth" />
        </div>
        <div className="earth-description col-12 lg:col-6">
          <h2 className="helveFont">
            Your business continuity <br></br> and growth depend <br></br> on
            nature‘s intactness
          </h2>
          <p>
            kuyua provides insights into your company's impact on <br></br>{' '}
            nature, highlighting risks and providing improvement <br></br>{' '}
            measures to reduce risks and increase positive impact. <br></br>{' '}
            Embrace sustainability in your supply chain and ensure <br></br>{' '}
            your business thrives. Book a demo with kuyua today.
          </p>
        </div>
      </div>

      <div className="precentagee-cards grid">
        <div className="col-12 md:col-3">
          <div className="card">
            <h2 className="helveFont text-center">40%</h2>
            <p className="px-4">
              of the world economy is based on natural products and biological
              processes (UNEP)
            </p>
          </div>
        </div>
        {/* <div className="col-4 special-percentagee">
          <div className="card">
            <h2 className="helveFont">xx of xx</h2>
            <p>
              is the average amount of nature dependencies that companies have
              (kuyua)
            </p>
          </div>
        </div> */}
        <div className="col-12 md:col-3">
          <div className="card">
            <h2 className="helveFont text-center">190</h2>
            <p className="px-4">
              <span className="Poppins-bold">trillion USD %</span>
              is the value of annual ecosystem services (NABU)
            </p>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="card">
            <h2 className="helveFont text-center">80%</h2>
            <p className="px-4">
              of the ecosystem services examined are declining (WWF)
            </p>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="card">
            <h2 className="helveFont text-center">94%</h2>
            <p className="px-4">
              of companies surveyed report biodiversity loss as a long, medium
              or short term risk (Capgemini Research Institute)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
