import { Link } from 'react-router-dom';
import env1 from '../../assets/footer/env1.png';
import env2 from '../../assets/footer/env2.png';
import env3 from '../../assets/footer/env3.png';
import env4 from '../../assets/footer/env4.png';
import env5 from '../../assets/footer/env5.png';
import env6 from '../../assets/footer/env6.png';
import env7 from '../../assets/footer/env7.png';
import linkedin from '../../assets/footer/linkedin.png';
import logo from '../../assets/footer/footerLogo.png';
import './Footer.scss';

function Footer() {
  const altDescription = 'Symbol for Sustainable Development Goal';

  return (
    <footer className="footer pl-5 pr-3 lg:px-5 xl:px-7 poppins-regular">
      <div className="rows-container grid gap-5 lg:gap-0">
        <div className="row-one col-12  lg:col-5">
          <div>
            <h3 className="mt-0">Kuyua GmbH</h3>
            <p>
              Bornstraße 3
              <br />
              20146 Hamburg
              <br />
              Commercial Register: HRB 182987
              <br />
              Registration court: Hamburg
            </p>
          </div>
          <div className="icon-container">
            <img src={env1} alt={altDescription} width={55} height={55} />
            <img src={env2} alt={altDescription} width={55} height={55} />
            <img src={env3} alt={altDescription} width={55} height={55} />
            <img src={env4} alt={altDescription} width={55} height={55} />
            <img src={env5} alt={altDescription} width={55} height={55} />
            <img src={env6} alt={altDescription} width={55} height={55} />
            <img src={env7} alt={altDescription} width={55} height={55} />
          </div>
        </div>
        <div className="row-two col-12 lg:col-4">
          <h2 className="mt-0">about us</h2>
          <p>
            Our mission is to empower companies to comprehend their impact on
            nature, as well as their dependencies and associated business risks
            – and to show paths towards safeguarding both, natural and capital
            assets.
          </p>
        </div>
        <div className="row-three  col-12  lg:col-3 lg:pl-4 xl:pl-7 flex flex-column">
          <Link to="/contact">contact us</Link>
          <Link to="/jobs">jobs</Link>
          <Link to="https://calendly.com/kya-christian/30?month=2024-06g">
            book a demo
          </Link>
          <Link to="https://www.kuyua.com/privacy-policy">
            imprint & privacy
          </Link>
          <Link to="https://www.linkedin.com/company/kuyua">
            <img src={linkedin} alt="kuyua linkedin profile" />
          </Link>
        </div>

        <div className="col-12">
          {/* <img src={logo} width={1300} height={320} alt="kuyua website logo" /> */}
        </div>
      </div>
      <div className="logo-container text-center">
        <img src={logo} alt="kuyua website logo" />
      </div>
      <small>© 2024 KUYUA</small>
    </footer>
  );
}

export default Footer;
