import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import BrandSection from './pages/home/brands/BrandSection';
import DescriptionSection from './pages/home/description/DescriptionSection';
import FeaturesSection from './pages/home/features/FeaturesSection';
import HeroSection from './pages/home/hero/HeroSection';
import TestmonialsSection from './pages/home/testmonials/TestmonialsSection';
import BlogSection from './pages/home/blog/BlogSection';

import metro from './assets/brands/metro.png';
import jungheinrich from './assets/brands/jungheinrich.png';
import melita from './assets/brands/melita.png';
import fuchs from './assets/brands/fuchs.png';
import vaude from './assets/brands/vaude.png';
import baum from './assets/brands/baum.png';
import marc_opolo from './assets/brands/marc_opolo.png';
import impact_solutions_commerzbank from './assets/brands/impact_solutions_commerzbank.png';
import umweltstiftung_michael_otto from './assets/brands/umweltstiftung_michael_otto.png';

import eutaxonomy from './assets/standards/eu_taxonomy.png';
import gri from './assets/standards/gri.png';
import csrd from './assets/standards/csrd.png';
import sbtn from './assets/standards/sbtn.png';
import issb from './assets/standards/issb.png';
import ShowCaseSection from './pages/home/show-case/ShowCaseSection';

const brandLogos = [
  { width: 140, height: 30, img: metro, col: 2 },
  { width: 174, height: 25, img: jungheinrich, col: 2 },
  { width: 100, height: 55, img: melita, col: 2 },
  { width: 80, height: 80, img: fuchs, col: 2 },
  { width: 200, height: 40, img: vaude, col: 3 },
  { width: 90, height: 40, img: baum, col: 2 },
  { width: 256, height: 25, img: marc_opolo, col: 3 },
  { width: 183, height: 80, img: impact_solutions_commerzbank, col: 3 },
  { width: 223, height: 80, img: umweltstiftung_michael_otto, col: 3 },
];

const standardLogos = [
  { width: 75, height: 75, img: eutaxonomy, col: 2 },
  { width: 75, height: 75, img: gri, col: 2 },
  { width: 80, height: 75, img: csrd, col: 2 },
  { width: 392, height: 75, img: sbtn, col: 4 },
  { width: 82, height: 75, img: issb, col: 2 },
];

function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <DescriptionSection />
      <BrandSection
        logos={brandLogos}
        customizelogosClass="logo-brand-container"
        customizeGridGapClass="null"
        title="clients and partners"
      />
      <FeaturesSection />
      {/* <TestmonialsSection /> */}
      <BrandSection
        logos={standardLogos}
        customizelogosClass="logo-brand-container"
        customizeGridGapClass="null"
        title="Aligned with international standards and frameworks"
      />
      <ShowCaseSection />
      {/* comment out for now until we develop the blogs website */}
      {/* <BlogSection /> */}
      <Footer />
    </>
  );
}

export default App;
