import './ShowCaseSection.scss';
import macbook from '../../../assets/macbook.png';
import { Link } from 'react-router-dom';

function ShowCaseSection() {
  return (
    <section className="show-case poppins-regular">
      <div className="front-show">
        <h2 className="helveFont">
          Driven by <br /> data, <br />
          inspired by <br /> nature.
        </h2>
        <div className="img-container">
          <img src={macbook} alt="kuyua website on macbook" />
        </div>
      </div>
      <div className="show-description">
        <h2 className="helveFont">
          Aligned with international standards <br /> providing actionable data
        </h2>
        <p>
          Our AI powered platform provides you with consoldidated data on nature{' '}
          <br />
          risks of any production site or supplier location. The holistic
          materiality <br /> assessment and scoring enable your company to
          identify and prioritize <br /> tailored action plans for risk
          reduction and improvement. 
        </p>

        <button className="bgBtn">
          <Link to="https://calendly.com/kya-christian/30?month=2024-06">
            Book a demo
          </Link>
        </button>
      </div>
    </section>
  );
}

export default ShowCaseSection;
