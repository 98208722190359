// import { Link } from 'react-router-dom';
import './DescriptionSection.scss';

function DescriptionSection() {
  return (
    <section className="description grid mx-0 poppins-regular">
      <div className="description-content col-12 md:col-6 ">
        <h2 className="helveFont text-2xl md:text-5xl">
          IS YOUR BUSINESS <br /> CONTRIBUTING <br /> TO NATURE's DECLINE or{' '}
          <br /> part of the solution?
        </h2>
        <p>
          The rapid loss of nature, with up to 150 species disappearing daily,
          doesn't just threaten biodiversity – it jeopardizes over $44 trillion
          of the world's GDP. Every action and decision your business makes can
          either exacerbate or alleviate this crisis.{' '}
        </p>
        <p>
          kuyua provides insights into your company's impact on nature,
          highlighting risks and providing improvement measures to reduce risks
          and increase positive impact. Embrace sustainability in your supply
          chain and ensure your business thrives. Book a demo with kuyua today.
        </p>
        {/* <button className="bgBtn">
          <Link to="https://calendly.com/kya-christian/30?month=2024-06">
            Book a demo
          </Link>
        </button>{' '} */}
      </div>
      <div className="banner hidden md:block col-offset-1 col-5"></div>
    </section>
  );
}

export default DescriptionSection;
