import './Header.scss';
import logo from '../../assets/kuyuaLogo.png';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header poppins-regular flex flex-row justify-content-between align align-items-center">
      <div className="mt-2 mx-auto lg:mx-0">
        <Link to="/">
          <img src={logo} alt="kuyua website logo" width={194} height={58} />
        </Link>
      </div>
      <nav className="hidden lg:block">
        <ul className="list-none flex gap-[79px] align-items-center">
          <li>
            <Link to="/contact">Contact us</Link>
          </li>
          <li>
            <Link to="/jobs">Jobs</Link>
          </li>
          <li>
            <Link to="https://calendly.com/kya-christian/30?month=2024-06">
              Book a demo
            </Link>
          </li>
          <li className="text-center">
            <button className="login-btn">Log in</button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
