import '../../App.css';
import './Jobs.scss';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

function Jobs() {
  return (
    <>
      <Header />
      <div className="jobsContent">
        <h1 className="helveFont">Jobs</h1>
        <p className="poppins-bold">
          Send us your application to{'  '}
          <a href="mailto:info@kuyua.com">info@kuyua.com</a>
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Jobs;
