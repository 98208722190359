import './BrandSection.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState, useRef, useEffect } from 'react';

function BrandSection({ title, logos, customizelogosClass }) {
  const [width, setWidth] = useState(window.innerWidth);

  const sliderRef = useRef(null);
  const scrollAmount = 100;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <section className="brand-section">
      <h3 className="poppins-regular text-xl md:text-4xl">{title}</h3>
      <div className="brands-logo px-4 md:px-6 md:py-0">
        {(logos.length > 5 || isMobile) && (
          <button
            className="nav-btn"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft -= scrollAmount;
            }}
          >
            <i
              className="pi pi-chevron-left"
              style={{ fontSize: 2 + 'em' }}
            ></i>
          </button>
        )}

        <div className="grid scrollImgs" ref={sliderRef}>
          {logos.map((logo, index) => (
            <div className={`col-12 md:col-${logo.col}`} key={`brand${index}`}>
              <div className={` img-container ${customizelogosClass}`}>
                <LazyLoadImage
                  width={logo.width}
                  height={logo.height}
                  src={logo.img}
                  alt="partners brands logos"
                />
              </div>
            </div>
          ))}
        </div>

        {(logos.length > 5 || isMobile) && (
          <button
            className="nav-btn"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft += scrollAmount;
            }}
          >
            <i class="pi pi-chevron-right" style={{ fontSize: 2 + 'em' }}></i>
          </button>
        )}
      </div>
    </section>
  );
}

export default BrandSection;
