import { Link } from 'react-router-dom';
import './blog.scss';
import blog1 from '../../../assets/blog/blog1.png';
import blog2 from '../../../assets/blog/blog2.png';
import blog3 from '../../../assets/blog/blog3.png';

function BlogSection() {
  return (
    <section className="blog poppins-regular">
      <h2 className="helveFont poppins-bold text-4xl md:text-7xl lg:text-8xl">
        Kuyua blog
      </h2>
      <div className="blog-cards grid ">
        <div className="xl:col-4 lg:col-6">
          <div className="card">
            <div className="image-container">
              <img alt="earth" src={blog1} />
            </div>
            <h3>
              Fun Fact Friday: Mental <br /> Health Awareness Month
            </h3>
            <p>
              Embark on a bespoke path to <br />{' '}
              being nature positive, protecting <br /> precious natural
              assets...
            </p>
            <Link to="/">read more</Link>
          </div>
        </div>
        <div className="xl:col-4 lg:col-6">
          <div className="card">
            <div className="image-container">
              <img alt="earth" src={blog2} />
            </div>
            <h3>
              Greece Leads the Charge in <br /> Marine Conservation
            </h3>
            <p>
              Greece has taken a bold step forward <br /> in marine
              conservation, greece has <br /> taken a bold step...
            </p>
            <Link to="/">read more</Link>
          </div>
        </div>
        <div className="xl:col-4 lg:col-6">
          <div className="card ">
            <div className="image-container">
              <img alt="earth" src={blog3} />
            </div>
            <h3>
              Unravelling all Biodiversity <br /> Credits, a novel
            </h3>
            <p>
              Biodiversity credits, a novel financing <br /> instrument
              attracting private capital <br /> for...
            </p>
            <Link to="/">read more</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
